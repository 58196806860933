






import Vue from 'vue';

export default Vue.extend({
  name: 'Cooperation',
  head() {
    return {
      title: '展会合作-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
      ],
    };
  },
});
