

















































































































































































import { Context } from '@nuxt/types';
import Vue from 'vue';
import _ from 'lodash';
import { ResponseCode } from '~/util/constant';
import FairItem from '~/pages/home/component/FairItem.vue';
import FairUser from '~/components/FairUser/index.vue';
import Avatar from '~/components/Avatar/index.vue';
import { $cookies } from '~/util/api';
import { getXOssProcess } from '~/util/common';

enum ETag {
  '普通' = 0,
  '延期' = 1,
  '变更' = 2,
  '取消' = 3,
}

export default Vue.extend<any, any, any>({
  name: 'Exposer',
  components: {
    FairItem,
    FairUser,
    Avatar,
  },
  async asyncData(ctx: Context) {
    const lang = _.get(ctx, 'query.lang', null);
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    const { id } = ctx.params;
    let user: Record<string, any> = {};
    let hotFair = [];

    const resps: any[] = await Promise.all([
      ctx.$axios.post('/api/followeApi/getExhibitionFollowedUserInfo', {
        uid: id,
      }),
    ]);

    const [userRes] = resps;

    if (userRes.code === ResponseCode.SUCCESS) {
      user = userRes.data;
    } else {
      return ctx.error({
        message: userRes.errorMessage,
        path: ctx.route.fullPath,
        statusCode: 404,
      });
    }

    const industryId = _.get(user, 'industrys[0].industryId', null);
    if (industryId) {
      const resps2: any[] = await Promise.all([
        ctx.$axios.post('/api/exhibition/list', {
          locale: langApi,
          selectTimeType: 1,
          industryId, // 行业展会
          pageNum: 1,
          pageSize: 8,
        }),
      ]);

      const [hotFairRes] = resps2;
      if (hotFairRes.code === ResponseCode.SUCCESS) {
        hotFair = hotFairRes.data.records;
      }
    } else {
      const hotFairRes: any = await ctx.$axios.post('/api/exhibition/list', {
        locale: langApi,
        selectTimeType: 1,
        recommond: '1',
        pageNum: 1,
        pageSize: 8,
        sortScale: 'desc',
      }); // 热门推荐

      if (hotFairRes.code === ResponseCode.SUCCESS) {
        hotFair = hotFairRes.data.records;
      }
    }

    return {
      user,
      hotFair,
      uid: id,
      exhibitionTotal: user.exhibitionCount,
    };
  },
  data() {
    return {
      ETag,
      exhibitionTotal: 0,
      headimg: require('@/assets/images/headimg.jpg'),
      ckeckFollow: false,
    };
  },
  created() {
    this.checkFollow();
  },
  methods: {
    getXOssProcess,
    shouldLogin() {
      const accessToken = $cookies.get('access_token');
      if (!accessToken) {
        this.$login.show();
      }
      return !!accessToken;
    },
    async followUser() {
      const res: any = await this.$axios.post(`/api/userFollowApi/followUser`, {
        followUserId: this.uid,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.checkFollow();
        this.$message.success('关注成功！');
      }
    },
    async delFollow() {
      const res: any = await this.$axios.post(
        `/api/userFollowApi/unFollowUser`,
        {
          followUserId: this.uid,
        }
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.checkFollow();
        this.$message.success('取消关注！');
      }
    },
    async checkFollow() {
      const res: any = await this.$axios.post(
        `/api/userFollowApi/checkUserFollow`,
        {
          followUserId: this.uid,
        }
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.ckeckFollow = res.data;
      }
    },
    // 关注展会
    async handleFollowFair(fair: any) {
      const { id } = fair;
      await this.$store.commit(
        'user/setOrigins',
        location.origin + this.$route.fullPath
      );
      await this.$store.commit('user/setAction', 4);
      await this.$store.commit('user/setIndustryId', 0);

      if (this.shouldLogin()) {
        // const res: any = await this.$axios.get(`/api/user/exhibition/${id}`);
        const res: any = await this.$axios.post(
          `/api/exhibition-follow/follow`,
          { exhibitionId: id }
        );
        if (res.code === ResponseCode.SUCCESS) {
          this.$message.success('关注成功！');
        }
      }
    },
    async handlePageChange(val: number) {
      const res: any = await this.$axios.post(
        '/api/followeApi/getExhibitionFollowedUserInfo',
        {
          uid: this.$data.uid,
          pageSize: 10,
          pageNum: val,
        }
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.$set(this, 'user', res.data);
        this.$set(this, 'exhibitionTotal', res.data.exhibitionCount);
      }
    },
  },
  head(): Record<string, any> {
    const { userName } = this.$data.user.userInfo || {};
    return {
      title: `${userName}用户中心-聚展`,
      meta: [
        {
          name: 'keywords',
          content: ``,
        },
        {
          name: 'description',
          content: ``,
        },
      ],
    };
  },
});
